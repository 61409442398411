import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Button
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const CampaignFilters = ({ filters={    }, setFilters }) => {
  const classes = useStyles();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleSpeciesChange = (event) => {
    const { value } = event.target;
    // setFilters((prevFilters) => ({
    //   ...prevFilters,
    //   species: value === 'both' ? null : value,
    // }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // TODO: Implement filter logic
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="startAge"
            label="Start Age"
            type="number"
            value={filters.startAge}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="endAge"
            label="End Age"
            type="number"
            value={filters.endAge}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="transactionPeriod"
            label="Transaction Period"
            type="number"
            value={filters.transactionPeriod}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="primaryCodeDuration"
            label="Primary Code Duration"
            type="number"
            value={filters.primaryCodeDuration}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="secondaryCodeDuration"
            label="Secondary Code Duration"
            type="number"
            value={filters.secondaryCodeDuration}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel>Species</InputLabel>
            <Select value={filters.species || 'both'} onChange={handleSpeciesChange}>
              <MenuItem value="canine">Canine</MenuItem>
              <MenuItem value="feline">Feline</MenuItem>
              <MenuItem value="both">Both</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="lastTransactionDuration"
            label="Last Transaction Duration"
            type="number"
            value={filters.lastTransactionDuration}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      </Grid>
      <div style={{ marginTop: '20px' }}>
        <Button type="submit" variant="contained" color="primary">
          Apply Filters
        </Button>
      </div>
    </form>
  );
};

export default CampaignFilters;
