import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Button,
  Divider,
  Tooltip,
  Card,
  CardContent
} from '@mui/material';

import { styled } from '@mui/system';

// Optional: example background for the hero
// Replace with your own image or gradient
const HeroSection = styled(Box)({
  background: `linear-gradient(135deg, #1a73e8 0%, #4285f4 100%)`,
  color: '#fff',
  padding: '4rem 1rem',
  textAlign: 'center',
  borderRadius: '8px',
});

export default function AwesomeRegistrationForm() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  // State for form fields
  const [practiceName, setPracticeName] = useState('');
  const [practiceWebsite, setPracticeWebsite] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [pims, setPims] = useState('');
  const [zoetisRepName, setZoetisRepName] = useState('');
  const [registrantType, setRegistrantType] = useState('Myself');

  // “Which brand of the following do you utilize?”
  const [parasitePreventivesK9, setParasitePreventivesK9] = useState('');
  const [parasitePreventivesFel, setParasitePreventivesFel] = useState('');
  const [vaccines, setVaccines] = useState('');
  const [diagnosticLabInHouse, setDiagnosticLabInHouse] = useState('');
  const [diagnosticLabSendOut, setDiagnosticLabSendOut] = useState('');

  const handleSubmit = () => {
    // Gather form data
    const formData = {
      practiceName,
      practiceWebsite,
      contactName,
      contactEmail,
      contactPhone,
      pims,
      zoetisRepName,
      registrantType,
      parasitePreventivesK9,
      parasitePreventivesFel,
      vaccines,
      diagnosticLabInHouse,
      diagnosticLabSendOut,
    };
    console.log('Form Data:', formData);

    // In a real app, you'd typically post this to a server.
    // For demo purposes, we show the "thank you" state.
    setFormSubmitted(true);
  };

  // "Thank You" screen
  if (formSubmitted) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
          Thank you for registering!
        </Typography>
        <Typography sx={{ mb: 3 }}>
          The next step is to schedule a brief but necessary meeting with one of our
          specialists to finalize the details. You can do so by clicking the button
          below to schedule a time that is convenient for you.
        </Typography>

        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            // onClick={() => window.open('https://yourSchedulingLink.com', '_blank')}
          >
            Schedule NOW!
          </Button>
        </Box>

        <Typography sx={{ mt: 3 }}>
          If you are unable to find a time that fits your schedule, please email us at{' '}
          <a href="mailto:clientservices@verticalvet.com">
            clientservices@verticalvet.com
          </a>{' '}
          with your need and we will be in touch!
        </Typography>
      </Container>
    );
  }

  // Main form
  return (
    <Box sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh', py: 4 }}>
      {/* Hero Section */}
      <Container maxWidth="md" sx={{ mb: 4 }}>
        <HeroSection>
          <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
            Client Communication Campaign Registration
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
            Please fill out the form below to register for a campaign.{' '}
            <strong>
              Once you click Submit, you’ll schedule a campaign onboard meeting.
            </strong>{' '}
            This brief meeting ensures we finalize all details and customize your
            campaign effectively.
          </Typography>
        </HeroSection>
      </Container>

      {/* Form Container */}
      <Container maxWidth="md">
        <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
          <CardContent sx={{ p: { xs: 2, md: 4 } }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              Practice Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Tooltip title="Enter the official name of your practice/clinic">
                  <TextField
                    label="Practice Name"
                    placeholder="e.g., Otterson Valley Animal Clinic"
                    fullWidth
                    value={practiceName}
                    onChange={(e) => setPracticeName(e.target.value)}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Practice Website"
                  placeholder="e.g., https://OttersonValleyVet.com"
                  fullWidth
                  value={practiceWebsite}
                  onChange={(e) => setPracticeWebsite(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Contact Name"
                  placeholder="Primary contact or manager"
                  fullWidth
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Contact Email"
                  placeholder="Your best email for communication"
                  fullWidth
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Contact Phone"
                  placeholder="(555) 555-5555"
                  fullWidth
                  value={contactPhone}
                  onChange={(e) => setContactPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="PIMS"
                  placeholder="Practice management system"
                  fullWidth
                  value={pims}
                  onChange={(e) => setPims(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Zoetis Reps Name"
                  placeholder="If applicable"
                  fullWidth
                  value={zoetisRepName}
                  onChange={(e) => setZoetisRepName(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
              <FormControl>
                <FormLabel>Who is registering?</FormLabel>
                <RadioGroup
                  row
                  value={registrantType}
                  onChange={(e) => setRegistrantType(e.target.value)}
                >
                  <FormControlLabel value="Myself" control={<Radio />} label="Myself" />
                  <FormControlLabel
                    value="Zoetis Rep"
                    control={<Radio />}
                    label="Zoetis Rep"
                  />
                  <FormControlLabel value="OV Team" control={<Radio />} label="OV Team" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Divider sx={{ my: 3 }} />

            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              Brands You Utilize
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Let us know which brands you currently work with (parasite preventives,
              vaccines, etc.), so we can tailor your campaign.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Parasite Preventives: K9"
                  placeholder="e.g., Simparica Trio, NexGard, etc."
                  fullWidth
                  value={parasitePreventivesK9}
                  onChange={(e) => setParasitePreventivesK9(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Parasite Preventives: Fel"
                  placeholder="e.g., Revolution, Advantage, etc."
                  fullWidth
                  value={parasitePreventivesFel}
                  onChange={(e) => setParasitePreventivesFel(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Vaccines"
                  placeholder="e.g., brand(s) used for core or lifestyle vaccines"
                  fullWidth
                  value={vaccines}
                  onChange={(e) => setVaccines(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Diagnostic Lab:
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="In-house"
                      placeholder="e.g., brand or equipment name"
                      fullWidth
                      value={diagnosticLabInHouse}
                      onChange={(e) => setDiagnosticLabInHouse(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Send out"
                      placeholder="e.g., Antech, Idexx, etc."
                      fullWidth
                      value={diagnosticLabSendOut}
                      onChange={(e) => setDiagnosticLabSendOut(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* SUBMIT BUTTON */}
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              <Button
                variant="contained"
                size="large"
                sx={{ px: 5, py: 1.5 }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
