import {
	Container,
	Grid,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Typography
} from "@mui/material";
import Page from "../../components/Page";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import TemplateCreationDialog from "./TemplateCreationDialog";
import { useEffect, useState } from "react";
import axios from "../../utils/axios";
import { format } from "date-fns"; // Import date formatting library

const useStyles = makeStyles((theme) => ({
	addCard: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	card: {
		borderRadius: "10px",
		boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
		transition: "all 0.3s",
		"&:hover": {
			transform: "translateY(-3px)",
			boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
		},
	},
	media: {
		height: 200,
		backgroundPosition: "center",
        display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	content: {
		padding: theme.spacing(2),
	},
	title: {
		fontWeight: 600,
		fontSize: "1.25rem",
		color: "#333",
	},
	detailsGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(2, 1fr)",
		gridColumnGap: theme.spacing(2),
		marginTop: theme.spacing(1),
	},
	detail: {
		fontSize: "0.75rem",
		color: "#666",
	},
}));

export default function CampaignTemplates() {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [templates, setTemplates] = useState([]); // Initialize as empty

	const navigate = useNavigate();

	const handleTemplateSelection = (templateData) => {
		console.log(templateData);
		navigate(`/dashboard/emailEditor/${templateData._id}`);
	};

	const handleDialogOpen = () => {
		setOpen(true);
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const fetchTemplates = async () => {
			try {
				// Make the GET request (assuming the endpoint is /api/templates)
				const response = await axios.get("/templates");
				if (response.data && Array.isArray(response.data)) {
					setTemplates(response.data);
				}
			} catch (error) {
				console.error("Failed to fetch templates:", error);
			}
		};

		fetchTemplates();
	}, []); // The empty dependency array ensures this effect runs once when the component mounts

	return (
		<Page>
			<Container maxWidth="lg">
				<Typography variant="h4" gutterBottom>
					Choose a Template
				</Typography>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={6} md={4} lg={3}>
						<Card
							className={`${classes.card} ${classes.addCard}`}
							onClick={(e) => handleDialogOpen(true)}
						>
							<CardActionArea>
								<CardMedia className={classes.media} title={"Add Template"}>
									<AddIcon fontSize="large" />
								</CardMedia>
								<CardContent>
									<Typography gutterBottom variant="h6" component="div">
										{"Add Template"}
									</Typography>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>

					{templates.map((template, index) => (
						<Grid item key={index} xs={12} sm={6} md={4} lg={3}>
							<Card
								className={`${classes.card} ${
									template.special && classes.addCard
								}`}
								onClick={(e) => handleTemplateSelection(template)}
							>
								<CardActionArea>
									<CardMedia
										className={classes.media}
										image={template.image}
										title={template.title}
									/>
									<CardContent className={classes.content}>
										<Typography className={classes.title} gutterBottom>
											{template.name}
										</Typography>
										<div className={classes.detailsGrid}>
											<Typography className={classes.detail}>
												Created by {template.createdBy}
												<br />
												{format(new Date(template.createdAt), "PPP")}
											</Typography>
											<Typography className={classes.detail}>
												Updated by {template.updatedBy}
												<br />
												{format(new Date(template.updatedAt), "PPP")}
											</Typography>
										</div>
									</CardContent>
								</CardActionArea>
							</Card>
						</Grid>
					))}
				</Grid>
				{/* Dialog for adding a new template */}
				<TemplateCreationDialog
					open={open}
					onOpen={handleDialogOpen}
					onClose={handleDialogClose}
					onContinue={handleTemplateSelection}
				/>
			</Container>
		</Page>
	);
}
