import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// K9 drop-down values
const k9CampaignOptions = [
  'General Wellness',
  'Annual Bloodwork',
  'Osteoarthritis',
  'Itchy Pets',
  'Lifestyle Vaccines',
  'Fecal Testing',
  'Year-round Prevention',
  'Simparica Trio',
  'ProHeart 12'
];

// Feline drop-down values
const felCampaignOptions = [
  'General Wellness',
  'Annual Bloodwork',
  'Osteoarthritis',
  'Itchy Pets',
  'Lifestyle Vaccines',
  'Fecal Testing',
  'Year-round Prevention',
  'Revolution Plus',
  'Bonqat'
];

// Helper to create a blank campaign object
const createCampaign = () => ({
  k9Version: '',
  felVersion: '',
  launchMonth: null,
  halfOfMonth: '1st',
  specialMessaging: false
});

export default function CampaignForm() {
  const [practiceName, setPracticeName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [pimsChanged, setPimsChanged] = useState('No');
  const [pimsSystem, setPimsSystem] = useState('');
  const [otherChanges, setOtherChanges] = useState('');

  // Start with one blank campaign (feel free to start with an empty array instead).
  const [campaigns, setCampaigns] = useState([createCampaign()]);

  // Add a new campaign slot, up to 4
  const handleAddCampaign = () => {
    if (campaigns.length >= 4) {
      alert('You can only choose up to 4 campaigns.');
      return;
    }
    setCampaigns([...campaigns, createCampaign()]);
  };

  // Remove a specific campaign slot
  const handleRemoveCampaign = (index) => {
    const updated = [...campaigns];
    updated.splice(index, 1);
    setCampaigns(updated);
  };

  // Update a campaign field by index
  const handleCampaignChange = (index, field, value) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[index][field] = value;
    setCampaigns(updatedCampaigns);
  };

  // Handle form submission
  const handleSubmit = () => {
    const formData = {
      practiceName,
      contactName,
      contactEmail,
      contactPhone,
      pimsChanged,
      pimsSystem,
      otherChanges,
      campaigns
    };
    console.log('Form Data:', formData);
    alert('Form submitted! Check console for details.');
  };

  return (
    <Box sx={{ backgroundColor: '#f7f7f7', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="md">
        {/* Header / Hero Section */}
        <Box
          sx={{
            backgroundColor: 'primary.main',
            color: '#fff',
            borderRadius: 2,
            p: 4,
            mb: 4,
            textAlign: 'center'
          }}
        >
          <Typography variant="h3" gutterBottom>
            Re-register for Your 2025 Campaigns
          </Typography>
          <Typography variant="subtitle1">
            Keep your clinic’s marketing on track all year long. Fill out the form
            below to select your preferred campaigns and get ready for a successful
            2025!
          </Typography>
        </Box>

        {/* Main Form */}
        <Box
          sx={{
            backgroundColor: '#fff',
            p: { xs: 2, md: 4 },
            borderRadius: 2,
            boxShadow: 2
          }}
        >
          <Typography variant="h5" gutterBottom>
            Practice Information
          </Typography>

          {/* 2-column layout for larger screens */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Practice Name"
                value={practiceName}
                onChange={(e) => setPracticeName(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Contact Name"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Contact Email"
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Contact Phone"
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
                margin="normal"
              />
            </Grid>
          </Grid>

          {/* Radio for PIMS */}
          <Box mt={2}>
            <FormControl component="fieldset" margin="normal">
              <FormLabel>Have you changed your PIMS system in the past 12 months?</FormLabel>
              <RadioGroup
                row
                value={pimsChanged}
                onChange={(e) => setPimsChanged(e.target.value)}
              >
                <FormControlLabel value="No" control={<Radio />} label="No" />
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              </RadioGroup>
            </FormControl>
          </Box>

          {pimsChanged === 'Yes' && (
            <TextField
              fullWidth
              label="If yes, to what system?"
              value={pimsSystem}
              onChange={(e) => setPimsSystem(e.target.value)}
              margin="normal"
            />
          )}

          {/* Other changes */}
          <TextField
            fullWidth
            label="Any other changes? (moved location, updated logo, etc.)"
            value={otherChanges}
            onChange={(e) => setOtherChanges(e.target.value)}
            margin="normal"
            multiline
          />

          {/* Campaign Section */}
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Choose up to 4 campaigns
          </Typography>
          <Typography variant="body2" gutterBottom>
            Our recommendation is one campaign per quarter to keep clients engaged
            year-round.
          </Typography>

          {/* Render each campaign */}
          {campaigns.map((campaign, index) => (
            <Accordion key={index} sx={{ mb: 2 }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Campaign {index + 1}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>K9 Version</InputLabel>
                      <Select
                        value={campaign.k9Version}
                        label="K9 Version"
                        onChange={(e) =>
                          handleCampaignChange(index, 'k9Version', e.target.value)
                        }
                      >
                        <MenuItem value="">None</MenuItem>
                        {k9CampaignOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Feline Version</InputLabel>
                      <Select
                        value={campaign.felVersion}
                        label="Feline Version"
                        onChange={(e) =>
                          handleCampaignChange(index, 'felVersion', e.target.value)
                        }
                      >
                        <MenuItem value="">None</MenuItem>
                        {felCampaignOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={['year', 'month']}
                        label="Launch Month"
                        value={campaign.launchMonth}
                        onChange={(newValue) =>
                          handleCampaignChange(index, 'launchMonth', newValue)
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth margin="normal" />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl component="fieldset" margin="normal">
                      <FormLabel>Preferred half of the month</FormLabel>
                      <RadioGroup
                        row
                        value={campaign.halfOfMonth}
                        onChange={(e) =>
                          handleCampaignChange(index, 'halfOfMonth', e.target.value)
                        }
                      >
                        <FormControlLabel value="1st" control={<Radio />} label="1st" />
                        <FormControlLabel value="2nd" control={<Radio />} label="2nd" />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={campaign.specialMessaging}
                          onChange={(e) =>
                            handleCampaignChange(
                              index,
                              'specialMessaging',
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="I will have special messaging or a promo for this campaign, please contact me."
                    />
                  </Grid>

                  {/* Remove button */}
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveCampaign(index)}
                    >
                      Remove Campaign
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}

          {/* Button to add a new campaign, limited to 4 */}
          {campaigns.length < 4 && (
            <Box sx={{ textAlign: 'left', mb: 2 }}>
              <Button variant="contained" onClick={handleAddCampaign}>
                Add Another Campaign
              </Button>
            </Box>
          )}

          {/* Submit */}
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button variant="contained" color="primary" size="large" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
